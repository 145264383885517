import React from 'react';
import ReactDOM from 'react-dom';
import DesktopDropdownMenu from '../homepage_components/desktop_dropdown_menu';
import EmbeddedMenu from '../homepage_components/embedded_menu';
import axios from 'axios';
const post = axios.post;
import { xhrConfig } from 'form_components/apis/api';

export const abandonListing = async (returnPath = '/') => {
  const _response = await post('/post/listings/abandon', {}, xhrConfig());
  window.location.href = returnPath;
};

export const pluckKeys = (keys, origObj) =>
  keys.reduce((newObj, k) => ({ ...newObj, [k]: origObj[k] }), {});

export const categoryIdFromContext = (context) => {
  return {
    have_space: 0,
    seeking_space: 0,
    opportunity: 1,
    jobs: 1,
  }[context];
};

export const validateEmail = ({ email }) => {
  let errors = {};
  if (!email) {
    errors.email = 'An email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export const optionsFor = (list) =>
  list.map((item, index) => (
    <option key={index} value={item[1]}>
      {item[0]}
    </option>
  ));

export const DisplayFormikState = (props) => {
  // return ''
  return (
    <div style={{ margin: '1rem 0' }}>
      <h3 style={{ fontFamily: 'monospace' }} />
      <pre
        style={{
          fontFamily: 'monospace',
          background: '#f6f8fa',
          fontSize: '1rem',
          padding: '.5rem',
          textAlign: 'left',
        }}
      >
        <strong>props</strong> = {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  );
};

export const handleFormSubmit = (values, actions, updateAttributes) => {
  updateAttributes(values)
    .then(() => {
      actions.setSubmitting(false);
      actions.resetForm({ values: values });
    })
    .catch((error) => {
      actions.setSubmitting(false);
      if (error.response && error.response.status === 400) {
        actions.setErrors(error.response.data);
      } else {
        actions.setErrors('Unknown error submitting form.');
      }
    });
};

export const formatDateMonDayOridinal = (date) => {
  // const date = new Date(Date.parse(dateStr))
  const nth = (n) =>
    ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
  const monthStr = date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
    month: 'short',
  });
  const day = date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
    day: 'numeric',
  });
  const dayStr = day + nth(parseInt(day));
  return monthStr + ' ' + dayStr;
};

export const loadScript = (src) => {
  let tag = document.createElement('script');
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName('body').appendChild(tag);
};

export const setupNav = () => {
  let scrollPos = 0;
  const nav = document.querySelector('.mobileBottomNav');

  function debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
      method();
    }, delay);
  }

  function checkPosition() {
    let windowY = window.scrollY;
    if (windowY < 0) windowY = 0;
    if (windowY <= scrollPos) {
      // Scrolling UP
      nav.classList.remove('mobileBottomNav-hidden');
      nav.classList.add('mobileBottomNav-show');
    } else {
      // Scrolling DOWN
      nav.classList.remove('mobileBottomNav-show');
      nav.classList.add('mobileBottomNav-hidden');
    }
    scrollPos = windowY;
  }

  const handleBottomNavScroll = () => debounce(checkPosition, 60);

  document.addEventListener('DOMContentLoaded', () => {
    const desktopDropdownMenuEl = document.getElementById(
      'desktopDropdownMenuRoot',
    );
    if (desktopDropdownMenuEl) {
      ReactDOM.render(<DesktopDropdownMenu />, desktopDropdownMenuEl);
    }
    const embeddedMenuEl = document.getElementById('embeddedMenuRoot');
    if (embeddedMenuEl) {
      ReactDOM.render(<EmbeddedMenu />, embeddedMenuEl);
    }

    // 1100 pixels wide is current breakpoint
    if (nav && document.documentElement.clientWidth < 1100) {
      window.addEventListener('scroll', handleBottomNavScroll);
    }
  });
  window.onresize = () => {
    if (nav && document.documentElement.clientWidth < 1100) {
      window.addEventListener('scroll', handleBottomNavScroll);
    } else {
      window.removeEventListener('scroll', handleBottomNavScroll);
    }
  };
};

export const saveBookmark = async (listing_id, user_id) => {
  post(
    '/account/bookmarks',
    { bookmark: { listing_id: listing_id, user_id: user_id } },
    xhrConfig(),
  );
};

export const removeBookmark = async (listing_id, user_id) => {
  post(
    '/account/bookmarks/destroy',
    { bookmark: { listing_id: listing_id, user_id: user_id } },
    xhrConfig(),
  );
};

export const toggleNotifyRepost = async (listing_id, user_id) => {
  post(
    '/account/bookmarks/toggle_notify_repost',
    { bookmark: { listing_id: listing_id, user_id: user_id } },
    xhrConfig(),
  );
};
